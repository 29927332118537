import { Button, CssVarsProvider, Sheet, Typography } from '@mui/joy';
import './App.css';
import GithubImage from './assets/github.png'

function App() {
  return (
    <CssVarsProvider>
      <Sheet sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'center',
      }} variant="soft">
        <Typography sx={{mb: '10px'}} level="h2" component="h2">
          Hi, i'm sparxooo 👋
        </Typography>
        <Typography level="h4">
          I work by day, program by night
        </Typography>
        <Typography sx={{mt: '30px'}}>Follow me on Github to find out what i'm up to!</Typography>
        
        <Button sx={{mt: '10px'}} component="a" href="https://www.github.com/sparxooo" startDecorator={<img src={GithubImage} height={32} alt="Github Follow"/>} color='neutral' size='md'>Follow me on Github!</Button>

      </Sheet>
    </CssVarsProvider>
  );
}

export default App;
